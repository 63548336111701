import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({ handleLeftDrawerToggle }) => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    console.log("We are in home screen");
  } else {
    console.log("We are not in home screen");

  }

  return (
    <AppBar position="fixed" sx={{ paddingLeft: '15px', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleLeftDrawerToggle}
          sx={{ marginRight: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" sx={{color: "#fff"}} noWrap>
          Облік замовлень
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
