// Import Material-UI icons to replace the Tabler icons:
import ShieldIcon from '@mui/icons-material/Shield';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import GroupIcon from '@mui/icons-material/Group';

// Create the icons object with MUI icons:
const icons = {
  IconShieldChevron: ShieldIcon,
  IconUserCircle: AccountCircleIcon,
  IconAlien: SentimentVeryDissatisfiedIcon,
  IconUsers: GroupIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const UsersPages = {
    id: 'users',
    title: 'Користувачі',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Список користувачів',
            type: 'item',
            url: '/users/list',
            icon: icons.IconUsers,
            breadcrumbs: true
        },
        {
            id: 'user-page',
            title: 'Користувачі',
            url: '/users/list',
            type: 'collapse',
            children: [
                {
                    id: 'order-page',
                    title: 'Користувач № ',
                    type: 'item',
                    pattern: '/users/list/(\\d+)',
                }
            ]
        },

        {
            id: 'employees-list',
            title: 'Водії',
            type: 'item',
            url: '/users/drivers/list',
            breadcrumbs: true,
        },
        {
            id: 'employee-page',
            title: 'Водії',
            url: '/users/drivers/list',
            type: 'collapse',
            children: [
                {
                    id: 'order-page',
                    title: 'Водій № ',
                    type: 'item',
                    pattern: '/users/drivers/list/(\\d+)',
                }
            ]
        },
        {
            id: 'clients-list',
            title: 'Клієнти',
            type: 'item',
            url: '/users/clients/list',
            breadcrumbs: true,
        },
        {
            id: 'client-page',
            title: 'Клієнти',
            url: '/users/clients/list',
            type: 'collapse',
            children: [
                {
                    id: 'client-page-id',
                    title: 'Клієнт № ',
                    type: 'item',
                    pattern: '/users/clients/list/(\\d+)',
                }
            ]
        },
    ]
};


export const UsersMenuItems = {
    id: 'users',
    title: 'Користувачі',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Список користувачів',
            type: 'item',
            url: '/users/list',
            icon: icons.IconUsers,
            breadcrumbs: true,
            permission: 1,
        },
        {
            id: 'drivers-list',
            title: 'Водії',
            type: 'item',
            url: '/users/drivers/list',
            icon: icons.IconShieldChevron,
        },
        {
            id: 'clients-list',
            title: 'Клієнти',
            type: 'item',
            url: '/users/clients/list',
            icon: icons.IconUserCircle,
        },
    ]
};

export default UsersMenuItems;
