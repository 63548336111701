// Import Material-UI icons to replace the Tabler icons:
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PlaceIcon from '@mui/icons-material/Place';


// Create the icons object with MUI icons:
const icons = {
  IconCars: DirectionsCarIcon,
  IconLocations: PlaceIcon,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export const SettingsPages = {
    id: 'settings',
    title: 'Налаштування',
    type: 'group',
    children: [
        {
            id: 'cars',
            title: 'Машини',
            type: 'item',
            url: '/settings/cars',
        },
        {
            id: 'locations',
            title: 'Населені пункти',
            type: 'item',
            url: '/settings/locations',
        },
    ]
};

export const SettingsMenuItems = {
    id: 'settings',
    title: 'Налаштування',
    type: 'group',
    children: [
        {
            id: 'cars',
            title: 'Машини',
            type: 'item',
            url: '/settings/cars',
            icon: icons.IconCars,
            breadcrumbs: true
        },
        {
            id: 'locations',
            title: 'Населені пункти',
            type: 'item',
            url: '/settings/locations',
            icon: icons.IconLocations,
            breadcrumbs: true
        },
    ]
};

export default SettingsMenuItems;
