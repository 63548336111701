import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'config';

export const fetchAllUsers = createAsyncThunk('users/list', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/users/list`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllDrivers = createAsyncThunk('users/list/driver', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/users/list/driver`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllClients = createAsyncThunk('users/list/client', async function (params, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/users/list/client`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUserById = createAsyncThunk('users/list/id', async function (id, { rejectWithValue }) {
    try {
        const response = await fetch(`${API_URL}/users/list/${id}`);

        if (!response.ok) {
            throw new Error('Server Error!');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

// Helper function for handling errors
const setError = (state, action) => {
    state.status = 'rejected';
    state.error = action.payload;
};

// Creating the users slice with builder callback notation
export const UsersSlice = createSlice({
    name: 'users',
    initialState: {
        status: '',
        loading: false,
        error: null,
        all: [],
        list: [],
        admins: [],
        drivers: [],
        clients: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchAllUsers
            .addCase(fetchAllUsers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchAllUsers.fulfilled, (state, action) => {
                state.status = 'resolved';
                state.all = action.payload.data;
            })

            // fetchAllClients
            .addCase(fetchAllClients.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchAllClients.fulfilled, (state, action) => {
                state.status = 'resolved';
                state.clients = action.payload.list;
            })

            // fetchAllDrivers
            .addCase(fetchAllDrivers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchAllDrivers.fulfilled, (state, action) => {
                state.status = 'resolved';
                state.drivers = action.payload.list;
            })
            
            // fetchUserById
            .addCase(fetchUserById.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.status = 'resolved';
                // Populate `list` or other field depending on your needs
                state.list = action.payload.data;
            })

            // setError
            .addCase(fetchAllUsers.rejected, setError)
            .addCase(fetchAllClients.rejected, setError)
            .addCase(fetchAllDrivers.rejected, setError)
            .addCase(fetchUserById.rejected, setError);
    }
});

export default UsersSlice.reducer;
