import { combineReducers } from 'redux';

// reducer import
import app from './app';
import users from './users';
import customizationReducer from './customizationReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
    app: app,
    users: users,
    customization: customizationReducer,
});

export default rootReducer;