import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';

export * from './app';
export * from './users';
// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
    reducer: rootReducer
    // middleware: (getDefaultMiddlware) => getDefaultMiddlware().concat(goodsApi.middleware)
});
