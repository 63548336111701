import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Material-UI imports
import { useTheme } from "@mui/material/styles";
import { Box, Card, Grid2 as Grid, Typography } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const linkSX = {
  display: "flex",
  color: "grey.900",
  textDecoration: "none",
  alignItems: "center",
};

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({
  card = true,
  divider = true,
  icon = false,
  icons = true,
  maxItems = 8,
  navigation,
  rightAlign = false,
  separator = ChevronRightIcon,
  title = true,
  titleBottom = false,
  ...others
}) => {
  const theme = useTheme();
  const location = useLocation();

  const [main, setMain] = useState(null);
  const [item, setItem] = useState(null);

  const homeIconStyle = {
    width: "1rem",
    height: "1rem",
    color: theme.palette.secondary.main,
  };

  const iconStyle = {
    marginRight: theme.spacing(0.75),
    // marginTop: `-${theme.spacing(0.25)}`,
    width: "1rem",
    height: "1rem",
    color: theme.palette.secondary.main,
  };

  // Recursive function to find active route
  const findActiveRoute = (menu) => {
    if (menu.children) {
      menu.children.forEach((child) => {
        if (child.type === "collapse") {
          findActiveRoute(child);
        } else if (child.type === "item" && location.pathname === child.url) {
          setMain(menu);
          setItem(child);
        } else if (child.pattern) {
          const regex = new RegExp(child.pattern);
          if (regex.test(location.pathname)) {
            setMain(menu);
            setItem(child);
          }
        }
      });
    }
  };

  useEffect(() => {
    navigation?.items?.forEach((menu) => {
      if (menu.type === "group") {
        findActiveRoute(menu);
      }
    });
  }, [navigation, location.pathname]);

  // Breadcrumbs separator setup
  const SeparatorIcon = separator;

  // Main and item content
  let mainContent = null;
  let itemContent = null;

  if (main) {
    mainContent = (
      <Typography
        component={Link}
        to={main.url ?? "#"}
        variant="subtitle1"
        sx={linkSX}
      >
        {icons && main.icon && <main.icon style={iconStyle} />}
        {main.title}
      </Typography>
    );
  }

  let postId = "";
  if (item && item.pattern) {
    const regex = new RegExp(item.pattern);
    const found = location.pathname.match(regex);

    if (found) {
      postId = parseInt(found[1]);
    }
  }

  if (item) {
    itemContent = (
      <Typography
        variant="subtitle1"
        sx={{
          display: "flex",
          textDecoration: "none",
          alignItems: "center",
          color: theme.palette.grey[500],
        }}
      >
        {icons && item.icon && <item.icon style={iconStyle} />}
        {item.title}{postId}
      </Typography>
    );
  }

  return (
    <Card
      sx={{
        marginBottom: card ? theme.spacing(2) : 0,
        border: card ? "1px solid" : "none",
        borderColor: card ? theme.palette.primary[200] + 75 : "transparent",
        background: card ? theme.palette.background.default : "transparent",
      }}
      {...others}
    >
      <Box sx={{ p: 2, pl: card ? 2 : 0 }}>
        <Grid
          container
          direction={rightAlign ? "row" : "column"}
          justifyContent={rightAlign ? "space-between" : "flex-start"}
          alignItems={rightAlign ? "center" : "flex-start"}
          spacing={1}
        >
          {title && !titleBottom && item && (
            <Grid item="true">
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                {item.title}{postId}
              </Typography>
            </Grid>
          )}
          <Grid item="true">
            <MuiBreadcrumbs
              sx={{
                "& .MuiBreadcrumbs-separator": {
                  width: 16,
                  ml: 0.5,
                  mr: 0.5,
                },
              }}
              aria-label="breadcrumb"
              maxItems={maxItems}
              separator={<SeparatorIcon fontSize="small" />}
            >
              {/* Home Breadcrumb */}
              <Typography component={Link} to="/" sx={linkSX}>
                {icons && <HomeOutlinedIcon sx={homeIconStyle} />}
                {/* {icon && <HomeIcon sx={{ ...iconStyle, mr: 0 }} />} */}
                {!icon && "Dashboard"}
              </Typography>
              {mainContent}
              {itemContent}
            </MuiBreadcrumbs>
          </Grid>

          {title && titleBottom && item && (
            <Grid item="true">
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                {item.title}{postId}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
};

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.object.isRequired,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
};

export default Breadcrumbs;
