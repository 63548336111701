// Import Material-UI icons to replace the Tabler icons:
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChecklistIcon from '@mui/icons-material/Checklist';

// Create the icons object with MUI icons:
const icons = {
  IconDashboard: DashboardIcon,
  IconShoppingCart: ShoppingCartIcon,
  IconCheckupList: ChecklistIcon
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export const DashboardPages = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'report',
            title: 'Зведений звіт',
            type: 'item',
            url: '/',
        },
        {
            id: 'report',
            title: 'Зведений звіт',
            type: 'item',
            url: '/report',
        },
    ]
};

export const DashboardMenuItems = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'report',
            title: 'Зведений звіт',
            type: 'item',
            url: '/report',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
    ]
};

export default DashboardMenuItems;
