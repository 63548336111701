import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  Paper,
} from "@mui/material";

import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";

import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';


import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import { pageItems as navigationMenu } from "menu-items";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        // marginLeft: -(drawerWidth - 60),
        marginLeft: -drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
        width: `100%`,
        // width: `calc(100% - ${drawerWidth}px)`,
        padding: "10px",
        marginRight: "0",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

const MainLayout = ({ children }) => {
  const theme = useTheme();

  // const theme = {}
  // const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  // const navigationMenu = useSelector((state) => state.app.navigation);
  // const navigationMenu = pageItems;
  // console.log(navigationMenu);

  // Handle left drawer
  // const leftDrawerOpened = useSelector((state) => state.customization.opened ?? false);
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

  useEffect(() => {
    // dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [null]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* header */}
      <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />

      {/* drawer */}
      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={ChevronRightIcon} navigation={navigationMenu} icon title rightAlign />
        <Paper elevation={1} sx={{ padding: "1.2em"}}>
          <Outlet />
        </Paper>
      </Main>
      {/* <Customization /> */}

      {/* <Footer /> */}
    </Box>
  );
};

export default MainLayout;
