import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
// import NavigationScroll from 'layout/NavigationScroll';
// import LinearProgress from '@mui/material/LinearProgress';

// ==============================|| APP ||============================== //

const App = () => {
  // const customization = useSelector((state) => state.customization);
  // const loading = useSelector((state) => state.loading.status);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes({})}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
