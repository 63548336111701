import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// import AuthGuard from 'utils/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// orders
const OrdersList = Loadable(lazy(() => import('views/orders/List')));
const OrderPage = Loadable(lazy(() => import('views/orders/Page')));
const AddNewOrder = Loadable(lazy(() => import('views/orders/Add')));

// users
const UsersList = Loadable(lazy(() => import('views/users/List')));
const UserItem = Loadable(lazy(() => import('views/users/Item')));
const AddNewUser = Loadable(lazy(() => import('views/users/AddNewUser')));

const ClientsList = Loadable(lazy(() => import('views/users/clients/List')));
const DriversList = Loadable(lazy(() => import('views/users/drivers/List')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/report',
            element: <DashboardDefault />
        },

        {
            path: 'orders',
            children: [
                {
                    path: '*',
                    element: <OrdersList />
                },
                {
                    path: 'list',
                    element: <OrdersList />
                },
                {
                    path: 'list/:orderId',
                    element: <OrderPage />
                },
                {
                    path: 'list/add',
                    element: <AddNewOrder />
                },
            ]
        },

        {
            path: 'users',
            children: [
                {
                    path: '*',
                    element: <UsersList />
                },
                {
                    path: 'list',
                    element: <UsersList />
                },
                {
                    path: 'clients/list',
                    element: <ClientsList />
                },
                {
                    path: 'drivers/list',
                    element: <DriversList />
                },
                {
                    path: 'list/:orderId',
                    element: <UserItem />
                },
                {
                    path: 'list/add',
                    element: <AddNewUser />
                },
            ]
        },
    ]
};

export default MainRoutes;
