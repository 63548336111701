export const API_URL = 'https://fsm.api.geo-fast.com.ua';

const config = {
    basename: '',
    theme: 'dark',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;