import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        loading: false,
        modalOpened: false,
        page: [],
        API_URL: "https://fsm.api.geo-fast.com.ua/"
    },
    reducers: {
        updateLoadingStatus: (state, action) => {
            state.loading = action.payload ?? false;
        },
        setNavigation: (state, action) => {
            state.navigation = action.payload ?? [];
        },
        setPage: (state, action) => {
            console.log(action);
            state.page = action.payload ?? [];
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateLoadingStatus, setNavigation, setPage } = appSlice.actions;

export default appSlice.reducer;
