import React from "react";
// import ReactDOM from 'react-dom/client';
import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { store } from "store";

// style + assets
// import 'assets/scss/style.scss';
import config from "./config";
import { Box } from "@mui/material";

// Check if the app is in standalone display mode
const isStandalone = window.matchMedia("(display-mode: standalone)").matches;

// Choose Router type based on the display mode
const Router = isStandalone ? HashRouter : BrowserRouter;

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <Router basename={config.basename}>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
